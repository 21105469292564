import { render, staticRenderFns } from "./AuthModal.vue?vue&type=template&id=45c9f78d&scoped=true&"
import script from "./AuthModal.vue?vue&type=script&lang=js&"
export * from "./AuthModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c9f78d",
  null
  
)

export default component.exports