<template>
    <b-modal
            @show="$emit('resetModal')"
            @hidden="$emit('hidden')"
            @cancel="$emit('handleAuth',false)"
            @ok="$emit('handleAuth',true)"
            centered
            title="احراز هویت"
            ok-title="تایید هویت"
            cancel-title="عدم انطباق"
            ok-variant="success"
            cancel-variant="danger"
            v-model="authModal">
        <b-card-group >
             <b-card
                :img-src="image"
                img-alt="Card image cap"
                :title="'اطلاعات تطبیقی برای کد ملی : ' + nationalId"
                img-top
        >
        <b-card
                :img-src="document"
                img-alt="Card image cap"
                title="عکس دست نوشته"
                img-top
        ></b-card>
            <b-card-body class="p-0">
                <div
                        class="employee-task d-flex justify-content-between align-items-center"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75 align-self-center">
                            <feather-icon size="20" icon="UserIcon"/>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                {{ finoDatas.fullName }}
                            </h6>
                            <small>{{ finoDatas.live }}</small>
                        </b-media-body>
                    </b-media>
                    <div class="d-flex align-items-center">
                        <small class="text-muted mr-75">
                            {{ (finoDatas.percent > 90 ? 'تطبیق کامل' : finoDatas.percent > 60 ? 'تطابق نسبی':'عدم تطابق') + ' : ' + finoDatas.percent }}
                        </small>

                        <!-- chart -->
                        <vue-apex-charts
                                type="radialBar"
                                height="30"
                                width="30"
                                v-if="finoDatas.options"
                                :options="finoDatas.options"
                                :series="[finoDatas.percent]"
                        />
                    </div>
                </div>
            </b-card-body>
        </b-card>
        </b-card-group>
    </b-modal>
</template>

<script>
    import {
        BModal,
        BCard,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
        BCardGroup,
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import {$themeColors} from '@themeConfig'

    const $trackBgColor = '#e9ecef'

    export default {
        name: "AuthModal",
        props: ['finoDatas', 'authModal', 'nationalId', 'image','document'],
        components: {
            BModal,
            BCard,
            BCardBody,
            BMedia,
            BMediaAside,
            BMediaBody,
            VueApexCharts,
            BCardGroup
        },
        data() {
            return {
                chartColor: {danger: $themeColors.danger, success: $themeColors.success, warning: $themeColors.warning},
            }
        },
        watch:{
            'finoDatas.percent'(){
                console.log('skajhdf',this.finoDatas.percent)
                this.setChartOption()
            }
        },
        computed:{
            authColor() {
                return a =>  a > 90 ? 'success' : a > 60 ? 'warning' : 'danger';
            },
        },
        methods:{
            setChartOption(){
                this.finoDatas.options = {
                    grid: {
                        show: false,
                        padding: {
                            left: -15,
                            right: -15,
                            top: -12,
                            bottom: -15,
                        },
                    },
                    colors: [this.chartColor[this.authColor(Number(this.finoDatas.percent))]],
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                size: '22%',
                            },
                            track: {
                                background: $trackBgColor,
                            },
                            dataLabels: {
                                showOn: 'always',
                                name: {
                                    show: false,
                                },
                                value: {
                                    show: false,
                                },
                            },
                        },
                    },
                    stroke: {
                        lineCap: 'round',
                    },
                }
            }
        },
        created() {
            this.setChartOption()
        }
    }
</script>

<style scoped>

</style>