<template>
  <b-card no-body class="p-1">
    <div class="mb-1">
      <b-badge
          pill
          :variant="walletType === 'P2P'? 'success' : 'light-success'"
          @click="changeWallet('P2P')"
          :class="{'cursor-pointer' : walletType !=='P2P'}"
          class="mx-1"
      >
        کیف پول پلتفرم معاملاتی
      </b-badge>
      <b-badge
          pill
          :variant="walletType === 'EXCHANGE'? 'primary' : 'light-primary'"
          @click="changeWallet('EXCHANGE')"
          :class="{'cursor-pointer' : walletType !=='EXCHANGE'}"
          class="mx-1"
      >
        کیف پول پلتفرم صرافی
      </b-badge>
    </div>
    <div class="relative-over-x">
      <b-table
          ref="refUserListTable"
          :items="items"
          :small="true"
          responsive
          :fields="columns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="اطلاعاتی یافت نشد"
          :sort-desc.sync="isSortDirDesc"
          class="text-nowrap"
          style="min-height : 235px"
      >
        <template #cell(relatedCoin)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="`/coins/${data.item.relatedCoin || 'TOMAN'}.png`"
                  :variant="`light-info`"
                  :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
                :to="{ name: 'statistics', params: { id: data.item.relatedCoin + '_' + 'TOMAN' } }"
                class="font-weight-bold d-block text-nowrap"
            >
              {{ $coins[data.item.relatedCoin || 'TOMAN'].persianName }}
            </b-link>
            <small class="text-muted">{{ data.item.relatedCoin || 'TOMAN' }}</small>
          </b-media>
        </template>

        <template #cell(unitPrice)="data">
                    <span dir="ltr">
                        {{ $toLocal(data.item.wholePriceOfCredit / data.item.credit, 0) || 0 }}
                    </span>
        </template>

        <template #cell(credit)="data">
                    <span dir="ltr">
                        {{ $toLocal(data.item.credit, $decimal[data.item.relatedCoin]) || 0 }}
                    </span>
        </template>

        <template #cell(wholePriceOfCredit)="data">
                    <span dir="ltr">
                        {{ $toLocal(data.item.wholePriceOfCredit, 0) || 0 }}
                    </span>
        </template>

        <template #cell(fee)="data">
                    <span dir="ltr">
                        {{ $toLocal(data.item.fee) }}%
                    </span>
        </template>

        <!--                <template #cell(status)="data">-->
        <!--                    <b-badge-->
        <!--                            pill-->
        <!--                            :variant="'light-'+statusVariant(data.item.status)"-->
        <!--                            class="text-capitalize"-->
        <!--                    >-->
        <!--                        {{statusLabel(data.item.status)}}-->
        <!--                    </b-badge>-->
        <!--                </template>-->

        <template #cell(orders)="data">
          <feather-icon
              icon="ShareIcon"
              size="20"
              class="text-danger cursor-pointer"
              :badge="data.item.orders"
              badge-classes="badge-danger cursor-pointer"
              v-tooltip="'درخواست های برداشت'"
              @click="activeOrdersPage(data.item.relatedCoin)"
          />
          <feather-icon
              icon="ClockIcon"
              size="20"
              class="text-warning ml-2 cursor-pointer"
              v-tooltip="'تاریخچه'"
              @click="orderHistoryPage(data.item.relatedCoin)"
          />
        </template>

      </b-table>
    </div>

  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BMedia,
  BAvatar,
  BLink,
} from 'bootstrap-vue'
// import vSelect from 'vue-select';

export default {
  name: 'userWallets',
  components: {
    BCard,
    BTable,
    BBadge,
    BMedia,
    BAvatar,
    BLink,
  },
  props: ['customer'],
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 1,
    rows: 200,
    sortBy: '',
    relatedCoin: '',
    cryptoUnit: '',
    isSortDirDesc: '',
    userData: [],
    credit: '',
    isActive: false,
    withModal: false,
    dipModal: false,
    feeModal: false,
    perPageOptions: [5, 10, 20, 50, 100],
    walletType: 'P2P',
    items: [],
    columns: [
      {
        label: 'رمز ارز',
        key: 'relatedCoin',
        sortable: false,
      },
      {
        label: 'قیمت واحد',
        key: 'unitPrice',
        sortable: false,
      },
      {
        label: 'موجودی',
        key: 'credit',
        sortable: false,
      },
      {
        label: 'موجودی (تومان)',
        key: 'wholePriceOfCredit',
        sortable: false,
      },
      {
        label: 'آدرس کیف پول اختصاص یافته',
        key: 'address',
        sortable: false,
      },
      {
        label: 'درخواست ها',
        key: 'orders'
      },
    ],
  }),
  computed: {
    roleVariant() {
      const a = {
        master: 'danger',
        accounting: 'info',
        technical: 'warning',
        employee: 'secondary'
      }
      return e => a[e];
    },
    roleLabel() {
      const a = {
        master: 'مدیر ارشد',
        accounting: 'حسابدار',
        technical: 'مدیر فنی',
        employee: 'کارمند'
      }
      return e => a[e];
    },
    roleIcon() {
      const a = {
        master: 'ServerIcon',
        accounting: 'DivideSquareIcon',
        technical: 'SettingsIcon',
        employee: 'UserIcon'
      }
      return e => a[e];
    },
    statusVariant() {
      const a = {
        inactive: 'danger',
        active: 'success',
      }
      return e => a[e];
    },
    statusLabel() {
      const a = {
        inactive: 'غیر فعال',
        active: 'فعال',
      }
      return e => a[e];
    },
  },
  methods: {
    changeWallet(e) {
      this.walletType = e
      this.getData()
    },
    activeOrdersPage(id) {
      this.push('withdraw-orders', id)
    },
    orderHistoryPage(id) {
      this.push('withdraw-history', id)
    },
    push(to, id) {
      this.$router.push({name: to, query: {customer: this.customer, relatedCoin: id}})
    },
    async getData() {
      const res = await this.$axios('/wallets/customer-addresses-admin', {
        params: {
          customerId: Number(this.customer),
          walletType: this.walletType
        }
      })
      let items = []
      let index = 0
      for (let key1 in res.data) {
        items[index] = {}
        items[index].relatedCoin = key1
        for (let key2 in res.data[key1]) {
          items[index][key2] = res.data[key1][key2]
        }
        index++
      }
      console.log('wallet : ', items)
      this.items = items
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
